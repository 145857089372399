import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

const HeadersLink = () => {
  return (
    <div className='flex h-10 items-center bg-[#facab3] px-10 sm:hidden lg:px-8'>
      <div className="flex h-10 items-center justify-center bg-[#facab3] px-10 text-sm font-medium lg:px-8">
        <div className="flex w-full justify-between items-center h-[50px] text-pink-700 text-[0.7rem]">
          <ul className="flex justify-start items-center gap-2">
            <div className="font-sans flex cursor-pointer justify-center items-center gap-1 relative after:h-[12px] after:w-[1px] after:bg-[#c43d7c] after:-right-[12px]">
              <FlyoutLink href="#">Track Order</FlyoutLink>
            </div>
            <div className="font-sans flex cursor-pointer justify-center items-center gap-2 relative after:h-[12px] after:w-[1px] after:bg-[#c43d7c] after:-right-[12px]">
              <FlyoutLink href="#" FlyoutContent={ReturnContent}>7 Day Return</FlyoutLink>
            </div>
            <div className="font-sans flex cursor-pointer justify-center items-center gap-2 relative after:h-[12px] after:w-[1px] after:bg-[#c43d7c] after:-right-[12px]">
              <FlyoutLink href="#" FlyoutContent={SellContent}>Sell On Panchvastra</FlyoutLink>
            </div>
            <div className="font-sans flex cursor-pointer justify-center items-center gap-2 relative after:h-[12px] after:w-[1px] after:bg-[#c43d7c] after:-right-[12px]">
              <FlyoutLink href="#">Contact Us</FlyoutLink>
            </div>
            <div className="font-sans flex cursor-pointer justify-center items-center gap-1 relative after:h-[12px] after:w-[1px] after:bg-[#c43d7c] after:-right-[12px]">
              <FlyoutLink href="#">Bulk Order Inquiry</FlyoutLink>
              <p className="animate-bounce w-[25px] h-[13px] flex justify-center items-center font-sans text-white text-[7px] bg-pink-600 translate- rounded-sm mb-3">
                NEW
              </p>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

const FlyoutLink = ({ children, href, FlyoutContent }) => {
  const [open, setOpen] = useState(false);

  const showFlyout = open && FlyoutContent;

  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="group relative md-lg:hidden">
      <a href={href} className='relative text-pink-600'>{children}
        <span
          style={{
            transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
          }} className='absolute -bottom-2 -left-2 -right-2 h-1 origin-left rounded-full bg-pink-600 transition-transform duration-300 ease-out' />
      </a>
      <AnimatePresence>
        {showFlyout && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ x: "-50%" }}
            transition={{ duration: 0.3, ease: 'easeOut' }}
            className='absolute left-1/2 top-12 bg-white border text-black'>
            <div className='absolute -top-6 left-0 ring-0 h-6 bg-transparent' />
            <div className='absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 border bg-white' />
            <FlyoutContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
};

const ReturnContent = () => {
  return (
    <div className=' w-64 bg-white shadow-xl'>
      <p className='font-mono text-xs text-justify mr-5 ml-5 mt-5 mb-2'><b className='text-red-800'>Safety First : </b>
        We are committed to ensuring a 100% Purchase Protection to customers by offering them genuine products, robust & secure payment gateways and easy returns for items purchased on Panchvastra.com </p>
      <p className='font-mono text-xs text-justify mr-5 ml-5'><b className='text-red-800'>Easy Returns : </b>
        Looking at returning/exchanging your product? It’s simple. You have to raise a return request within 7 days of delivery of the product.</p>
    </div>
  );
};

const SellContent = () => {
  return (
    <div className=' w-64 bg-white shadow-xl'>
      <p className='font-mono text-xs text-justify mr-5 ml-5 mt-5 mb-2'>
        Panchvastra is a platform where sellers can list and sell a variety of products. If you're looking to sell on Panchvastra, here’s a create account to get started: </p>
    </div>
  );
};


export default HeadersLink


