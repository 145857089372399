import React, { useEffect } from "react";
import { AiFillHeart, AiOutlineShoppingCart } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Ratings from "../Ratings";
import {
  add_to_card,
  messageClear,
  add_to_wishlist,
} from "../../store/reducers/cardReducer";
import { TypeAnimation } from "react-type-animation";

const FeatureProducts = ({ products }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);
  const { successMessage, errorMessage } = useSelector((state) => state.card);

  const add_card = (id) => {
    if (userInfo) {
      dispatch(
        add_to_card({
          userId: userInfo.id,
          quantity: 1,
          productId: id,
        })
      );
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(messageClear());
    }
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(messageClear());
    }
  }, [errorMessage, successMessage]);

  const add_wishlist = (pro) => {
    dispatch(
      add_to_wishlist({
        userId: userInfo.id,
        productId: pro._id,
        name: pro.name,
        price: pro.price,
        image: pro.images[0],
        discount: pro.discount,
        rating: pro.rating,
        slug: pro.slug,
      })
    );
  };
  return (
    <div className="w-[70%] text-center mx-auto">
      {/* Scroll Text start */}
      <div className="w-full mb-2">
        <TypeAnimation
          className="bg-gradient-to-r from-red-600 via-pink-500 to-orange-600 text-transparent bg-clip-text font-serif"
          sequence={[
            // Same substring at the start will only be typed out once, initially
            "New Arrival",
            1000, // wait 1s before replacing "New Arrival" with "Panchvastra"
            "New Arrival",
            1000,
            "Weekend Fashion",
            1000,
            "Festival Fashion",
            1000,
          ]}
          wrapper="span"
          speed={50}
          style={{ fontSize: "2em", display: "inline-block" }}
          repeat={Infinity}
        />
      </div>
      {/* Scroll Text End */}

      <div className="w-full grid grid-cols-4 md-lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-3 gap-4">
        {products.map((p, i) => (
          <div
            key={i}
            className="border group transition-all duration-500 hover:shadow-md hover:-mt-3"
          >
            <div className="relative overflow-hidden">
              {/* {
                                p.discount ? <div className='flex justify-center items-center absolute text-white w-[38px] h-[38px] rounded-full bg-red-500 font-semibold text-xs left-2 top-2'>{p.discount}%</div> : ""
                            } */}

              <img
                className="w-[400px] h-[420px] border sm:h-[60%]"
                src={p.images[0]}
                alt=""
              />
              <ul className="flex transition-all duration-700 -bottom-10 justify-center items-center gap-2 absolute w-full group-hover:bottom-3">
                <li
                  onClick={() => add_wishlist(p)}
                  className="w-[38px] h-[38px] cursor-pointer bg-white flex justify-center items-center rounded-full hover:bg-[#f7427e] hover:text-white hover:rotate-[720deg] transition-all"
                >
                  <AiFillHeart />
                </li>
                <Link
                  to={`/product/details/${p.slug}`}
                  className="w-[38px] h-[38px] cursor-pointer bg-white flex justify-center items-center rounded-full hover:bg-[#f7427e] hover:text-white hover:rotate-[720deg] transition-all"
                >
                  <FaEye />
                </Link>
                <li
                  onClick={() => add_card(p._id)}
                  className="w-[38px] h-[38px] cursor-pointer bg-white flex justify-center items-center rounded-full hover:bg-[#f7427e] hover:text-white hover:rotate-[720deg] transition-all"
                >
                  <AiOutlineShoppingCart />
                </li>
              </ul>
            </div>
            <div className="py-5 text-slate-500 sm:text-[0.6rem] sm:h-[100px] px-2">
              <h2>{p.name}</h2>

              <div className="flex justify-start items-center py-3 gap-3">
                <span className="text-lg sm:text-xs sm:text-slate-500 font-bold sm:mb-3">
                  ₹{p.price}
                </span>
                <div className="mb-7">
                  {p.discount ? (
                    <div className="absolute text-green-600 text-lg sm:text-xs sm:text-green-600 font-bold">
                      {p.discount}%
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex px-10 sm:hidden">
                  <Ratings ratings={p.rating} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureProducts;
