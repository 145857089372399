import React from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Categorys = () => {

    const { categorys } = useSelector(state => state.home)

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4
        },
        mdtablet: {
            breakpoint: { max: 991, min: 464 },
            items: 4
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 4
        },
        smmobile: {
            breakpoint: { max: 640, min: 0 },
            items: 4
        },
        xsmobile: {
            breakpoint: { max: 440, min: 0 },
            items: 4
        }
    }
    return (
        <div className='w-[90%] mx-auto relative'>

            <Carousel
                autoPlay={true}
                infinite={true}
                arrows={false}
                responsive={responsive}
                transitionDuration={500}
            >
                {
                    categorys.map((c, i) => <Link className='sm:w-[80px] sm:h-[80px] w-[200px] h-[200px] rounded-full block border-[#fac385] border-[0.1rem] hover:border-gray-300 shadow-md shadow-slate-400 hover:shadow-slate-800 mb-7 mr-0 ml-1' key={i} to={`/products?category=${c.name}`}>
                        <div className='w-full h-full relative p-[0.1rem]'>
                            <img className="w-full h-full rounded-full border border-[#facab3] " src={c.image} alt="category_image" />
                            <div className='absolute mt-2 w-full mx-auto font-base left-0 flex justify-center items-center'>
                                <span className='py-[2px] px-6 sm:text-[0.4rem] text-orange-500 text-[0.9rem] text-center hover:text-slate-800'>{c.name}</span>
                            </div>
                        </div>
                    </Link>)
                }
            </Carousel>

        </div>
    )
}

export default Categorys