import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaFacebookF, FaLinkedin } from 'react-icons/fa'
import { AiFillGithub, AiOutlineTwitter } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { AiFillShopping, AiFillHeart } from 'react-icons/ai'
import logo from '../assets/logo.png'

const Footer = () => {

    const { card_product_count, wishlist_count } = useSelector(state => state.card)
    const navigate = useNavigate()
    const { userInfo } = useSelector(state => state.auth)


    return (
        <footer className='bg-[#fdc699]'>
            <div className='w-[85%] flex flex-wrap mx-auto border-b py-16 md-lg:pb-10 sm:pb-6'>
                <div className='w-3/12 lg:w-4/12 sm:w-full'>
                    <div className='flex flex-col gap-3'>
                        <img className='w-[190px] h-[70x]' src={logo} alt="logo" />
                        <ul className='flex flex-col gap-2 text-slate-600 text-[0.9rem]'>
                            <li>Address : Mumbai, Maharashtra</li>
                            <li>Phone : 7905680759</li>
                            <li>Email : ujjwalfoundation73@gmail.com</li>
                        </ul>
                    </div>
                </div>
                <div className='w-2/12 lg:w-8/12 sm:w-full'>
                    <div className='flex justify-center sm:justify-start sm:mt-6 w-full'>
                        <div>
                            <h2 className='text-[0.9rem] text-slate-600 mb-2'>CUSTOMER SERVICE</h2>
                            <div className='flex justify-between gap-[80px] lg:gap-[40px]'>
                                <ul className='flex flex-col gap-2 text-slate-500 text-[0.8rem]'>
                                    <li>
                                        <Link>DOWNLOAD THE APP</Link>
                                    </li>
                                    <li>
                                        <a href="/MensKurta">SIZE CHART</a>
                                    </li>
                                    <li>
                                        <Link>SHIPPING & DELIVERY</Link>
                                    </li>
                                    <li>
                                        <Link>TRACK YOUR ORDER</Link>
                                    </li>
                                    <li>
                                        <Link>RETURNS</Link>
                                    </li>
                                    <li>
                                        <Link>FAQ's</Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-4/12 lg:w-8/12 sm:w-full'>
                    <div className='flex justify-center sm:justify-start sm:mt-6 w-full'>
                        <div>
                            <h2 className='text-[0.9rem] text-slate-600 mb-2'>ABOUT PANCHVASTRA</h2>
                            <div className='flex justify-between gap-[80px] lg:gap-[40px]'>
                                <ul className='flex flex-col gap-2 text-slate-500 text-[0.8rem]'>
                                    <li>
                                        <Link>ABOUT US</Link>
                                    </li>
                                    <li>
                                        <Link>OUR STORES</Link>
                                    </li>
                                    <li>
                                        <Link>CONTACT US</Link>
                                    </li>
                                    <li>
                                        <Link>RETURNS POLICY</Link>
                                    </li>
                                    <li>
                                        <Link>PRIVACY POLICY</Link>
                                    </li>
                                    <li>
                                        <Link>TERMS & CONDITIONS</Link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-3/12 lg:w-full lg:mt-6'>
                    <div className='w-full flex flex-col justify-start gap-6'>
                        <h2 className='font-mono text-[1rem] text-slate-600 mb-2'>SIGN UP AND SAVE</h2>
                        <span className='font-sans text-[0.8rem] text-slate-500'>Subscribe to get special offers, free giveaways, and once-in-a-lifetime deals.</span>
                        <div className='h-[50px] w-full relative'>
                            <input placeholder='Enter your mail' className='h-full bg-transparent w-full px-3 outline-0' type="text" />
                            <button className='h-full absolute right-0 text-pink-600 uppercase px-4 font-bold text-sm hover:text-slate-400 '>Subscribe</button><hr />
                        </div>
                        <ul className='flex justify-start items-center gap-3'>
                            <li>
                                <a className='w-[38px] h-[38px] hover:bg-[#1b9ff8] hover:text-white flex justify-center items-center bg-white rounded-full' href="https://facebook.com/panchvastra"><FaFacebookF /></a>
                            </li>
                            <li>
                                <a className='w-[38px] h-[38px] hover:bg-[#252021] hover:text-white flex justify-center items-center bg-white rounded-full' href="https://twitter.com/panchvastra"><AiOutlineTwitter /></a>
                            </li>
                            <li>
                                <a className='w-[38px] h-[38px] hover:bg-[#0e74f8] hover:text-white flex justify-center items-center bg-white rounded-full' href="https://linkedin.com/panchvastra"><FaLinkedin /></a>
                            </li>
                            <li>
                                <a className='w-[38px] h-[38px] hover:bg-[#92048b] hover:text-white flex justify-center items-center bg-white rounded-full' href="https://github.com"><AiFillGithub /></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='w-[85%] flex flex-wrap justify-center items-center text-slate-600 mx-auto py-5 text-center'>
                <span>Copyright ©2024 All rights reserved | Designed By <a className='text-pink-700 underline' href="https://instagram.com/panchvastra">@UjjwalGonda</a></span>
            </div>

            <div className='hidden fixed md-lg:block w-[50px] bottom-3 h-[110px] right-2 bg-white rounded-full p-2'>
                <div className='w-full h-full flex gap-3 flex-col justify-center items-center'>
                    <div onClick={() => navigate(userInfo ? '/card' : '/login')} className='relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#e2e2e2]'>
                        <span className='text-xl text-orange-500'><AiFillShopping /></span>
                        {
                            card_product_count !== 0 && <div className='w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]'>
                                {
                                    card_product_count
                                }
                            </div>
                        }
                    </div>
                    <div onClick={() => navigate(userInfo ? '/dashboard/my-wishlist' : '/login')} className='relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full bg-[#e2e2e2]'>
                        <span className='text-xl text-red-500'><AiFillHeart /></span>
                        {
                            wishlist_count !== 0 && <div className='w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]'>
                                {wishlist_count}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer