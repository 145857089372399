const local = "http://localhost:5000";
const production = "https://panchvastra-api.onrender.com";

let base_url = "";
let mode = "pro";

if (mode === "pro") {
  base_url = production;
} else {
  base_url = local;
}

export { base_url, mode };

export const stripe_sky =
  "pk_test_51Nk8Y4F0B89ncn3xWB6ZN3GsbVIVL7Jqfa3jxtIOpPkKHcleHZw4EMPJKd4cRwm34ZARBeYmAWwu3VxyYL1gb6OT00UKNSvfvb";
