import React, { Children, useEffect, useState } from "react";
import { GrMail } from "react-icons/gr";
import { ImSearch } from "react-icons/im";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import {
    FaLinkedinIn,
    FaFacebookF,
    FaUser,
    FaLock,
    FaList,
} from "react-icons/fa";
import {
    AiOutlineTwitter,
    AiFillGithub,
    AiFillHeart,
    AiFillShopping,
} from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    get_card_products,
    get_wishlist_products,
} from "../store/reducers/cardReducer";
import logo from "../assets/logo.png";

const Headers = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categorys } = useSelector((state) => state.home);
    const { userInfo } = useSelector((state) => state.auth);
    const { card_product_count, wishlist_count } = useSelector(
        (state) => state.card
    );

    const { pathname } = useLocation();
    const [showShidebar, setShowShidebar] = useState(true);
    const [categoryShow, setCategoryShow] = useState(true);
    const [searchValue, setSearchValue] = useState("");
    const [category, setCategory] = useState("");

    const search = () => {
        navigate(`/products/search?category=${category}&&value=${searchValue}`);
    };
    const redirect_card_page = () => {
        if (userInfo) {
            navigate(`/card`);
        } else {
            navigate(`/login`);
        }
    };

    useEffect(() => {
        if (userInfo) {
            dispatch(get_card_products(userInfo.id));
            dispatch(get_wishlist_products(userInfo.id));
        }
    }, [userInfo]);



    return (
        <div className="w-full bg-white">
            <div className="w-white">
                <div className="w-[85%] lg:w-[90%] mx-auto">
                    <div className="h-[80px] md-lg:h-[100px] flex justify-between items-center flex-wrap">
                        <div className="md-lg:w-full w-3/12 md-lg:pt-4">
                            <div className="flex justify-between items-center">
                                <Link to="/">
                                    <img
                                        className="h-[60px] w-[120px] mr-2 mb-3"
                                        src={logo}
                                        alt="logo"
                                    />
                                </Link>
                                <div
                                    className="justify-center items-center w-[30px] h-[30px] bg-white text-slate-600 border border-slate-600 rounded-sm cursor-pointer lg:hidden md-lg:flex xl:hidden hidden"
                                    onClick={() => setShowShidebar(false)}
                                >
                                    <span>
                                        <FaList />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="md-lg:w-full w-9/12">
                            <div className="flex justify-between md-lg:justify-center items-center flex-wrap pl-8">
                                <ul className="flex justify-start items-start gap-8 text-sm font-bold uppercase md-lg:hidden">
                                    <li>
                                        <Link
                                            className={`p-2 block ${pathname === "/" ? "text-[#f3ba85]" : "text-slate-400"
                                                }`}
                                        >
                                            Home
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/shops"
                                            className={`p-2 block ${pathname === "/shop"
                                                ? "text-[#2e2822]"
                                                : "text-slate-400 hover:text-[#f3ba85]"
                                                }`}
                                        >
                                            Mens
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={`p-2 block ${pathname === "/mens"
                                                ? "text-[#f3ba85]"
                                                : "text-slate-400 hover:text-[#f3ba85]"
                                                }`}
                                        >
                                            Womens
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={`p-2 block ${pathname === "/womens"
                                                ? "text-[#f3ba85]"
                                                : "text-slate-400 hover:text-[#f3ba85]"
                                                }`}
                                        >
                                            Boys
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            className={`p-2 block ${pathname === "/girls"
                                                ? "text-[#f3ba85]"
                                                : "text-slate-400 hover:text-[#f3ba85]"
                                                }`}
                                        >
                                            Girls
                                        </Link>
                                    </li>
                                </ul>

                                {/* Search component start                               */}
                                <div className='flex h-[35px] sm:w-[170px] sm:bottom-[50px] sm:left-5 items-center border border-orange-200 rounded-full relative gap-5'>
                                    <div className='relative after:absolute after:h-[25px] after:w-[1px] after:bg-[#fca76e] after:-right-[15px] md:hidden'>
                                        <select onChange={(e) => setCategory(e.target.value)} className='w-[150px] text-orange-300 text-xs bg-transparent px-5 h-full outline-0 border-none' name="" id="">
                                            <option value="">Select category</option>
                                            {
                                                categorys.map((c, i) => <option key={i} value={c.name}>{c.name}</option>)
                                            }
                                        </select>
                                    </div>
                                    <input className='w-full relative bg-transparent text-slate-500 text-xs mr-5 outline-0 px-3 h-full ' onChange={(e) => setSearchValue(e.target.value)} type="text" name="" id="" placeholder='what do you need' />
                                    <button onClick={search} className='right-0 bg-transparent absolute px-5 h-full font-sans uppercase text-pink-300 ml-5'><ImSearch></ImSearch></button>
                                </div>
                                {/* Search component end    */}

                                {/* bag and heart component start    */}
                                <div className="flex md-lg:hidden justify-center items-center gap-5">
                                    <div className="flex justify-center gap-5">
                                        <div
                                            onClick={() =>
                                                navigate(userInfo ? "/dashboard/my-wishlist" : "/login")
                                            }
                                            className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full"
                                        >
                                            <span className="text-xl text-red-400">
                                                <AiFillHeart />
                                            </span>
                                            {wishlist_count !== 0 && (
                                                <div className="w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                                                    {wishlist_count}
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            onClick={redirect_card_page}
                                            className="relative flex justify-center items-center cursor-pointer w-[35px] h-[35px] rounded-full "
                                        >
                                            <span className="text-xl text-orange-400">
                                                <AiFillShopping />
                                            </span>
                                            {card_product_count !== 0 && (
                                                <div className="w-[20px] h-[20px] absolute bg-green-500 rounded-full text-white flex justify-center items-center -top-[3px] -right-[5px]">
                                                    {card_product_count}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* bag and heart component end    */}

                                {/* Login Component start */}
                                <div>
                                    <div className="flex justify-center items-center text-[#fd7f7f] gap-10 sm:hidden">
                                        {userInfo ? (
                                            <Link
                                                className="flex cursor-pointer justify-center items-center gap-2 text-sm"
                                                to="/dashboard"
                                            >
                                                <span>
                                                    <FaUser />
                                                </span>
                                                <span>{userInfo.name}</span>
                                            </Link>
                                        ) : (
                                            <Link
                                                to="/login"
                                                className="flex cursor-pointer justify-center items-center gap-2 text-sm"
                                            >
                                                <span>
                                                    <FaLock />
                                                </span>
                                                <span>Login</span>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                {/* Login Component end */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="shadow-lg" />
            <div className="hidden md-lg:block">
                <div
                    onClick={() => setShowShidebar(true)}
                    className={`fixed duration-200 transition-all ${showShidebar ? "invisible" : "visible"
                        } hidden md-lg:block w-screen h-screen bg-[rgba(0,0,0,0.5)] top-0 left-0 z-20`}
                ></div>
                <div
                    className={`w-[300px] z-[9999] transition-all duration-200 fixed  ${showShidebar ? "-left-[300px]" : "left-0"
                        } top-0 overflow-y-auto bg-white h-screen py-6 px-8`}
                >
                    <div className="flex justify-start flex-col gap-6">
                        <Link to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                        <div className="flex justify-star items-center gap-10">
                            {userInfo ? (
                                <Link
                                    className="flex cursor-pointer justify-center items-center gap-2 text-sm"
                                    to="/dashboard"
                                >
                                    <span>
                                        <FaUser />
                                    </span>
                                    <span>{userInfo.name}</span>
                                </Link>
                            ) : (
                                <div className="flex cursor-pointer justify-center items-center gap-2 text-sm">
                                    <span>
                                        <FaLock />
                                    </span>
                                    <span>Login</span>
                                </div>
                            )}
                        </div>
                        <ul className="flex flex-col justify-start items-start font-mono text-md font-semibold uppercase">
                            <li>
                                <Link
                                    className={`py-2 block ${pathname === "/"
                                        ? "text-[#f3ba85]"
                                        : "text-slate-600 hover:text-[#f3ba85]"
                                        }`}
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={"/shops"}
                                    className={`py-2 block ${pathname === "/shops"
                                        ? "text-[#f3ba85]"
                                        : "text-slate-400 hover:text-[#f3ba85]"
                                        }`}
                                >
                                    Mens
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`py-2 block ${pathname === "/blog"
                                        ? "text-[#f3ba85]"
                                        : "text-slate-400 hover:text-[#f3ba85]"
                                        }`}
                                >
                                    Womens
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`py-2 block ${pathname === "/about"
                                        ? "text-[#f3ba85]"
                                        : "text-slate-400 hover:text-[#f3ba85]"
                                        }`}
                                >
                                    Boys
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className={`py-2 block ${pathname === "/contact"
                                        ? "text-[#f3ba85]"
                                        : "text-slate-400 hover:text-[#f3ba85]"
                                        }`}
                                >
                                    Girls
                                </Link>
                            </li>
                        </ul>
                        <div className="flex justify-center  items-center gap-4">
                            <a href="https://facebook.com/ujjwalgonda">
                                <FaFacebookF />
                            </a>
                            <a href="https://twitter.com/panchvastra">
                                <AiOutlineTwitter />
                            </a>
                            <a href="https://linkedin.com/panchvastra">
                                <FaLinkedinIn />
                            </a>
                            <a href="https://github.com/ujjwalgonda">
                                <AiFillGithub />
                            </a>
                        </div>
                        <ul className="flex flex-col justify-center items-center gap-3 text-[#1c1c1c]">
                            <li className="flex justify-start items-center gap-2  text-sm">
                                <span>
                                    <GrMail />
                                </span>
                                <span>ujjwalfoundation73@gmail.com</span>
                            </li>
                            <span className="font-mono text-sm text-[#f5b274]">
                                Multi Vendor
                            </span>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Headers;
