import React from 'react'

const Information = () => {
  return (
    <div className='w-full flex justify-center items-center'>
      <div className='w-[85%] border shadow-md mb-5'>
        <h1 className='bg-gradient-to-tl from-pink-500 via-yellow-400 to-pink-500 text-transparent bg-clip-text font-serif text-3xl flex justify-center items-center sm:text-xl mb-2 mt-2'>Shop From Panchvastra</h1>
        <p className='font-sans leading-relaxed text-left text-slate-600 sm:text-xs ml-10 mr-5 mb-3'>Panchvastra is an Indian brand that specializes in traditional and contemporary clothing and accessories that blend cultural heritage with modern design aesthetics. The name Panchvastra itself may be derived from the Sanskrit word "Panch" meaning "five" and "Vastra" meaning "clothing." This could symbolize the five elements of nature or the essence of diverse, traditional fabrics and styles that the brand offers.</p>
        <h1 className='flex justify-center items-center font-bold text-slate-500 sm:text-xs underline mb-2'>Why Choose Panchvastra?</h1>
        <p className=' text-slate-600 font-sans text-left sm:text-xs ml-10 mr-5 mb-3'><b className='font-bold text-slate-500'>Craftsmanship: </b>Panchvastra likely emphasizes handcrafted textiles and ethnic craftsmanship. Indian artisans may be involved in the creation of fabrics, designs, and embellishments, ensuring a unique touch to every piece.

          Fusion of Tradition and Modernity: The brand might offer a fusion of traditional Indian aesthetics and modern, comfortable fits, making it versatile for both contemporary and classic tastes.

          Sustainability: Many Indian clothing brands today focus on sustainable materials such as organic cotton, handwoven fabrics, and eco-friendly dyes, so Panchvastra may also prioritize sustainable fashion.
        </p>
        <h1 className='flex justify-center items-center font-bold text-slate-500 underline sm:text-xs mb-2'>Things to Look for While Shopping:</h1>
        <p className='text-slate-600 font-sans sm:text-xs ml-10 mr-5 mb-3'><a className='font-bold text-slate-500 '>Size and Fit : </a> Check the sizing chart for clothing. Indian brands sometimes have different size conventions, so it’s important to measure your body and match it to the brand's size guide.</p>
        <p className='text-slate-600 font-sans sm:text-xs ml-10 mr-5 mb-3'><a className='font-bold text-slate-500'>Fabric and Material : </a> Pay attention to the fabric details (especially if you have specific preferences like cotton or silk). If you have allergies or sensitivities, make sure to read the material descriptions.</p>
        <p className='text-slate-600 font-sans sm:text-xs ml-10 mr-5 mb-5'><a className='font-bold text-slate-500'>Return Policy : </a> Always check the return and exchange policy, especially for clothing and accessories that you might need to try on to see how they fit or feel.</p>
      </div>
    </div>
  )
}

export default Information
