import React from 'react'

const MensKurta = () => {
  return (

    <div className='bg-cyan-900 flex justify-center items-center h-screen'>
      <table className='shadow-2xl font-sans border-2 border-cyan-300 w-6/12'>
        <thead className='text-white'>
          <tr>
            <th className='py-3 bg-cyan-800'> Size</th>
            <th className='py-3 bg-cyan-800'> Chest</th>
            <th className='py-3 bg-cyan-800'> Waist</th>
            <th className='py-3 bg-cyan-800'> Hips</th>
            <th className='py-3 bg-cyan-800'> Shoulder</th>
            <th className='py-3 bg-cyan-800'> Sleeve Length</th>
            <th className='py-3 bg-cyan-800'> Neck</th>
            <th className='py-3 bg-cyan-800'> Kurta Length</th>
            <th className='py-3 bg-cyan-800'> Churidar Length</th>
            <th className='py-3 bg-cyan-800'> Dhoti Length</th>
          </tr>
        </thead>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-200 cursor-pointer duration-300'>
            <td className='py-3 px-6'>30</td>
            <td className='py-3 px-6'>34</td>
            <td className='py-3 px-6'>32</td>
            <td className='py-3 px-6'>36</td>
            <td className='py-3 px-6'>16</td>
            <td className='py-3 px-6'>23</td>
            <td className='py-3 px-6'>15</td>
            <td className='py-3 px-6'>38</td>
            <td className='py-3 px-6'>40</td>
            <td className='py-3 px-6'>34</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-300 cursor-pointer duration-300'>
            <td className='py-3 px-6'>32</td>
            <td className='py-3 px-6'>36</td>
            <td className='py-3 px-6'>34</td>
            <td className='py-3 px-6'>38</td>
            <td className='py-3 px-6'>17</td>
            <td className='py-3 px-6'>24</td>
            <td className='py-3 px-6'>15</td>
            <td className='py-3 px-6'>39</td>
            <td className='py-3 px-6'>42</td>
            <td className='py-3 px-6'>34</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-200 cursor-pointer duration-300'>
            <td className='py-3 px-6'>34</td>
            <td className='py-3 px-6'>38</td>
            <td className='py-3 px-6'>36</td>
            <td className='py-3 px-6'>40</td>
            <td className='py-3 px-6'>17</td>
            <td className='py-3 px-6'>24</td>
            <td className='py-3 px-6'>16</td>
            <td className='py-3 px-6'>40</td>
            <td className='py-3 px-6'>42</td>
            <td className='py-3 px-6'>36</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-300 cursor-pointer duration-300'>
            <td className='py-3 px-6'>36</td>
            <td className='py-3 px-6'>40</td>
            <td className='py-3 px-6'>38</td>
            <td className='py-3 px-6'>42</td>
            <td className='py-3 px-6'>17.5</td>
            <td className='py-3 px-6'>24</td>
            <td className='py-3 px-6'>16</td>
            <td className='py-3 px-6'>41</td>
            <td className='py-3 px-6'>44</td>
            <td className='py-3 px-6'>36</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-200 cursor-pointer duration-300'>
            <td className='py-3 px-6'>38</td>
            <td className='py-3 px-6'>42</td>
            <td className='py-3 px-6'>40</td>
            <td className='py-3 px-6'>44</td>
            <td className='py-3 px-6'>18</td>
            <td className='py-3 px-6'>24.5</td>
            <td className='py-3 px-6'>16.5</td>
            <td className='py-3 px-6'>42</td>
            <td className='py-3 px-6'>44</td>
            <td className='py-3 px-6'>38</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-300 cursor-pointer duration-300'>
            <td className='py-3 px-6'>40</td>
            <td className='py-3 px-6'>44</td>
            <td className='py-3 px-6'>42</td>
            <td className='py-3 px-6'>46</td>
            <td className='py-3 px-6'>19</td>
            <td className='py-3 px-6'>25</td>
            <td className='py-3 px-6'>17</td>
            <td className='py-3 px-6'>42</td>
            <td className='py-3 px-6'>46</td>
            <td className='py-3 px-6'>40</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-200 cursor-pointer duration-300'>
            <td className='py-3 px-6'>42</td>
            <td className='py-3 px-6'>46</td>
            <td className='py-3 px-6'>44</td>
            <td className='py-3 px-6'>48</td>
            <td className='py-3 px-6'>19</td>
            <td className='py-3 px-6'>26</td>
            <td className='py-3 px-6'>18</td>
            <td className='py-3 px-6'>44</td>
            <td className='py-3 px-6'>46</td>
            <td className='py-3 px-6'>40</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-300 cursor-pointer duration-300'>
            <td className='py-3 px-6'>44</td>
            <td className='py-3 px-6'>48</td>
            <td className='py-3 px-6'>46</td>
            <td className='py-3 px-6'>50</td>
            <td className='py-3 px-6'>20</td>
            <td className='py-3 px-6'>26</td>
            <td className='py-3 px-6'>18</td>
            <td className='py-3 px-6'>44</td>
            <td className='py-3 px-6'>48</td>
            <td className='py-3 px-6'>42</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-200 cursor-pointer duration-300'>
            <td className='py-3 px-6'>46</td>
            <td className='py-3 px-6'>50</td>
            <td className='py-3 px-6'>48</td>
            <td className='py-3 px-6'>50</td>
            <td className='py-3 px-6'>20</td>
            <td className='py-3 px-6'>27</td>
            <td className='py-3 px-6'>19</td>
            <td className='py-3 px-6'>46</td>
            <td className='py-3 px-6'>48</td>
            <td className='py-3 px-6'>42</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-300 cursor-pointer duration-300'>
            <td className='py-3 px-6'>48</td>
            <td className='py-3 px-6'>52</td>
            <td className='py-3 px-6'>50</td>
            <td className='py-3 px-6'>54</td>
            <td className='py-3 px-6'>21</td>
            <td className='py-3 px-6'>27</td>
            <td className='py-3 px-6'>19</td>
            <td className='py-3 px-6'>46</td>
            <td className='py-3 px-6'>50</td>
            <td className='py-3 px-6'>43</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-200 cursor-pointer duration-300'>
            <td className='py-3 px-6'>50</td>
            <td className='py-3 px-6'>54</td>
            <td className='py-3 px-6'>52</td>
            <td className='py-3 px-6'>56</td>
            <td className='py-3 px-6'>21</td>
            <td className='py-3 px-6'>28</td>
            <td className='py-3 px-6'>19</td>
            <td className='py-3 px-6'>47</td>
            <td className='py-3 px-6'>50</td>
            <td className='py-3 px-6'>43</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-300 cursor-pointer duration-300'>
            <td className='py-3 px-6'>52</td>
            <td className='py-3 px-6'>56</td>
            <td className='py-3 px-6'>54</td>
            <td className='py-3 px-6'>58</td>
            <td className='py-3 px-6'>22</td>
            <td className='py-3 px-6'>28</td>
            <td className='py-3 px-6'>19</td>
            <td className='py-3 px-6'>47</td>
            <td className='py-3 px-6'>52</td>
            <td className='py-3 px-6'>44</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-200 cursor-pointer duration-300'>
            <td className='py-3 px-6'>54</td>
            <td className='py-3 px-6'>58</td>
            <td className='py-3 px-6'>56</td>
            <td className='py-3 px-6'>60</td>
            <td className='py-3 px-6'>22</td>
            <td className='py-3 px-6'>29</td>
            <td className='py-3 px-6'>20</td>
            <td className='py-3 px-6'>48</td>
            <td className='py-3 px-6'>52</td>
            <td className='py-3 px-6'>44</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-300 cursor-pointer duration-300'>
            <td className='py-3 px-6'>56</td>
            <td className='py-3 px-6'>60</td>
            <td className='py-3 px-6'>58</td>
            <td className='py-3 px-6'>62</td>
            <td className='py-3 px-6'>23</td>
            <td className='py-3 px-6'>29</td>
            <td className='py-3 px-6'>20</td>
            <td className='py-3 px-6'>48</td>
            <td className='py-3 px-6'>54</td>
            <td className='py-3 px-6'>44</td>
          </tr>
        </tbody>
        <tbody className='text-cyan-900 text-center'>
          <tr className='bg-cyan-200 cursor-pointer duration-300'>
            <td className='py-3 px-6'>58</td>
            <td className='py-3 px-6'>62</td>
            <td className='py-3 px-6'>60</td>
            <td className='py-3 px-6'>64</td>
            <td className='py-3 px-6'>23</td>
            <td className='py-3 px-6'>30</td>
            <td className='py-3 px-6'>20</td>
            <td className='py-3 px-6'>49</td>
            <td className='py-3 px-6'>54</td>
            <td className='py-3 px-6'>44</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default MensKurta